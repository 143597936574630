define('ember-idx-forms/mixins/control', ['exports', 'ember'], function (exports, Em) {

  'use strict';

  exports['default'] = Em['default'].Mixin.create({
    classNameBindings: ['class'],
    "class": 'form-control',
    init: function init() {
      this._super();

      var propertyIsModel = this.get('parentView.propertyIsModel');
      if (propertyIsModel) {
        return Em['default'].Binding.from("model" + '.' + this.get('propertyName') + '.content').to('selection').connect(this);
      } else {
        return Em['default'].Binding.from("model" + '.' + this.get('propertyName')).to('value').connect(this);
      }
    },
    hasValue: Em['default'].computed('value', function () {
      return this.get('value') !== null;
    }).readOnly()
  });

});