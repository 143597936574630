define('ember-idx-forms/group', ['exports', 'ember', 'ember-idx-forms/mixins/in_form', 'ember-idx-forms/mixins/has_property', 'ember-idx-forms/mixins/has_property_validation'], function (exports, Em, InFormMixin, HasPropertyMixin, HasPropertyValidationMixin) {

  'use strict';

  exports['default'] = Em['default'].Component.extend(InFormMixin['default'], HasPropertyMixin['default'], HasPropertyValidationMixin['default'], {
    tagName: 'div',
    "class": 'form-group',
    layoutName: 'components/em-form-group',
    classNameBindings: ['class', 'hasSuccess', 'hasWarning', 'hasError', 'v_icons:has-feedback'],
    attributeBindings: ['disabled'],
    canShowErrors: false,
    canShowErrorsObserver: Em['default'].observer('form', 'form.model', function () {
      this.set('canShowErrors', false);
    }),
    hasSuccess: Em['default'].computed('status', 'canShowErrors', function () {
      var success;
      success = this.get('validations') && this.get('status') === 'success' && this.get('canShowErrors');
      this.set('success', success);
      return success;
    }),
    hasWarning: Em['default'].computed('status', 'canShowErrors', function () {
      var warning;
      warning = this.get('validations') && this.get('status') === 'warning' && this.get('canShowErrors');
      this.set('warning', warning);
      return warning;
    }),
    hasError: Em['default'].computed('status', 'canShowErrors', function () {
      var error;
      error = this.get('validations') && this.get('status') === 'error' && this.get('canShowErrors');
      this.set('error', error);
      return error;
    }),
    v_icons: Em['default'].computed.alias('form.v_icons'),
    v_success_icon: 'fa fa-check',
    v_warn_icon: 'fa fa-exclamation-triangle',
    v_error_icon: 'fa fa-times',
    validations: true,
    yieldInLabel: false,
    v_icon: Em['default'].computed('status', 'canShowErrors', function () {
      if (!this.get('canShowErrors')) {
        return;
      }
      switch (this.get('status')) {
        case 'success':
          return this.get('v_success_icon');
        case 'warning':
        case 'warn':
          return this.get('v_warn_icon');
        case 'error':
          return this.get('v_error_icon');
        default:
          return null;
      }
    }),
    init: function init() {
      return this._super();
    },

    /*
    Observes the helpHasErrors of the help control and modify the 'status' property accordingly.
     */

    /*
    Listen to the focus out of the form group and display the errors
     */
    focusOut: function focusOut() {
      return this.set('canShowErrors', true);
    }
  });

});