define('ember-cli-i18n/utils/stream', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].__loader.require('ember-metal/streams/stream')['default'];
	var readArray = Ember['default'].__loader.require('ember-metal/streams/utils')['readArray'];
	var read = Ember['default'].__loader.require('ember-metal/streams/utils')['read'];

	exports.readArray = readArray;
	exports.read = read;

});