define('ember-idx-forms/mixins/has_property', ['exports', 'ember'], function (exports, Em) {

  'use strict';

  exports['default'] = Em['default'].Mixin.create({
    property: void 0,
    propertyName: Em['default'].computed('parentView.property', function () {
      if (this.get('property')) {
        return this.get('property');
      } else if (this.get('parentView.property')) {
        return this.get('parentView.property');
      } else {
        return Em['default'].assert(false, 'Property could not be found.');
      }
    }),
    init: function init() {
      this._super();
      return Em['default'].Binding.from('model.errors.' + this.get('propertyName')).to('errors').connect(this);
    }
  });

});