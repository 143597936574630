/**
  @module ember-data
*/

/**
  All Ember Data methods and functions are defined inside of this namespace.

  @class DS
  @static
*/

/**
  @property VERSION
  @type String
  @default '1.0.0-beta.16.1'
  @static
*/
/*jshint -W079 */
var DS = Ember.Namespace.create({
  VERSION: '1.0.0-beta.16.1'
});

if (Ember.libraries) {
  Ember.libraries.registerCoreLibrary('Ember Data', DS.VERSION);
}

export default DS;
