define('ember-cli-tree/components/ember-tree', ['exports', 'ember', 'ember-cli-tree/templates/components/ember-tree'], function (exports, Ember, layout) {

  'use strict';

  var computed = Ember['default'].computed;
  var run = Ember['default'].run;

  exports['default'] = Ember['default'].Component.extend({
    layout: layout['default'],
    tagName: 'ul',
    classNames: ['ember-tree'],

    childrenKey: 'children',
    eagerCreate: true,
    expandEvent: 'click',
    showRest: false,
    isExpanded: computed.alias('node.isExpanded'),
    showOtherTextFmt: 'Show Other %@',
    sjowOnly: false,

    didInsertElement: function didInsertElement() {
      var _this = this;

      var $areaExpand = Ember['default'].$(this.$().find('.ember-tree-node-trigger-expand')[0]);
      var expandEvent = this.get('expandEvent');

      if (!$areaExpand) {
        console.warn("your tree component won't be expandable unless you nest {{ember-tree/trigger-expand}} component in it");
        return;
      }

      this.set('$areaExpand', $areaExpand);
      $areaExpand.on(expandEvent, function () {
        run(function () {
          if (_this.get('expandAction')) {
            _this.sendAction('expandAction', _this.get('node'), !_this.get('isExpanded'));
          } else {
            Ember['default'].set(_this.get('node'), 'isExpanded', !_this.get('isExpanded'));
          }
        });
      });
    },

    willInsertElement: function willInsertElement() {
      var $areaExpand = this.get('$areaExpand');

      if ($areaExpand) {
        $areaExpand.off(this.get('expandEvent'));
      }
    },

    children: computed('node', 'childrenKey', 'showOnly', function () {
      var key = this.get('childrenKey');
      var children = this.get('node')[key];

      if (!children) {
        return [];
      }

      var showOnly = this.get('showOnly');
      showOnly = showOnly ? showOnly : children.length;
      return children.slice(0, showOnly);
    }),

    childrenRest: computed('node', 'childrenKey', 'showOnly', function () {
      var key = this.get('childrenKey');
      var showOnly = this.get('showOnly');
      var children = this.get('node')[key];

      if (!children) {
        return [];
      }

      return children.slice(showOnly, children.length);
    }),

    showOtherText: computed('showOtherTextFmt', 'showOnly', 'childrenRest.length', function () {
      var showOnly = this.get('showOnly');
      var showOtherTextFmt = this.get('showOtherTextFmt');
      var numberLeft = this.get('childrenRest.length');

      if (!showOnly || !numberLeft) {
        return '';
      }
      return Ember['default'].String.fmt(showOtherTextFmt, numberLeft);
    }),

    hasRest: computed('showOnly', 'childrenRest.length', function () {
      return !!this.get('showOnly') && !!this.get('childrenRest.length');
    }),

    actions: {
      showOther: function showOther() {
        this.toggleProperty('showRest');
      },

      expandChild: function expandChild(node, isExpanded) {
        if (this.get('expandAction')) {
          this.sendAction('expandAction', node, isExpanded);
        } else {
          Ember['default'].set(node, 'isExpanded', isExpanded);
        }
      }
    }
  });

});