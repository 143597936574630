define('ember-cli-i18n/rules/zh', ['exports', 'ember-cli-i18n/rules/base'], function (exports, base) {

  'use strict';

  exports['default'] = function(value, result, path, countryCode) {
    return base['default'](['other'], value, result, path, countryCode, function(value) {
      switch(value) {
        default: return 'other';
      }
    });
  }

});