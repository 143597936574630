define('ember-idx-forms/checkbox', ['exports', 'ember', 'ember-idx-forms/group', 'ember-idx-forms/checkbox', 'ember-idx-forms/mixins/control'], function (exports, Em, FormGroupComponent, FormCheckboxComponent, ControlMixin) {

  'use strict';

  exports['default'] = FormGroupComponent['default'].extend({
    v_icons: false,
    validations: false,
    yieldInLabel: true,
    controlView: Em['default'].Checkbox.extend(ControlMixin['default'], {
      "class": false,
      model: Em['default'].computed.alias('parentView.parentView.model'),
      propertyName: Em['default'].computed.alias('parentView.parentView.propertyName'),
      init: function init() {
        this._super();
        return Em['default'].Binding.from("model." + this.get('propertyName')).to('checked').connect(this);
      }
    }),
    wrapperClass: Em['default'].computed('form.form_layout', function () {
      if (this.get('form.form_layout') === 'horizontal') {
        return 'col-sm-offset-2 col-sm-10';
      }
    }),
    labelWrapperClass: Em['default'].computed('form.form_layout', function () {
      if (this.get('form.form_layout') === 'horizontal') {
        return 'checkbox';
      }
      return null;
    }),
    "class": Em['default'].computed('form.form_layout', function () {
      if (this.get('form.form_layout') !== 'horizontal') {
        return 'checkbox';
      }
      return 'form-group';
    })
  });

});