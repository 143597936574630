define('ember-idx-utils/mixin/hotkeys-bindings', ['exports', 'ember'], function (exports, Em) {

  'use strict';

  exports['default'] = Em['default'].Mixin.create({
    /**
     * Add `hotkeysBindings` property as a `concatenatedProperties`.
     * @property concatenatedProperties
     * @type array
     */
    concatenatedProperties: ['hotkeysBindings'],
    keyMap: {
      8: "backspace",
      9: "tab",
      13: "return",
      16: "shift",
      17: "ctrl",
      18: "alt",
      224: "meta",
      112: "f1",
      113: "f2",
      114: "f3",
      115: "f4",
      116: "f5",
      117: "f6",
      118: "f7",
      119: "f8",
      120: "f9",
      121: "f10",
      122: "f11",
      123: "f12"
    },
    keyPressHandler: function(e) {
      var command;
      command = "";
      if (e.ctrlKey) {
        command += "ctrl+";
      }
      if (e.altKey) {
        command += "alt+";
      }
      if (e.shiftKey) {
        command += "shift+";
      }
      if (e.metaKey) {
        command += "meta+";
      }
      if (this.keyMap[e.which]) {
        command += this.keyMap[e.which];
      } else {
        command += String.fromCharCode(e.which).toLowerCase();
      }
      Em['default'].debug("hotkey command: " + command);
      return this.send(command);
    },
    keyDown: function(e) {
      return this.keyPressHandler(e);
    },
    keyUp: function(e) {
      return this.keyPressHandler(e);
    },
    keyPress: function(e) {
      return this.keyPressHandler(e);
    }
  });

});