define('ember-component-inbound-actions/inbound-actions', ['exports', 'ember', 'ember-component-inbound-actions/action-proxy'], function (exports, Em, ActionProxy) {

  'use strict';

  exports['default'] = Em['default'].Mixin.create({
    _inbound_actions_setup: Ember.on('init', function() {
          Em['default'].run.schedule('afterRender', this, function() {
            var proxy = ActionProxy['default'].create({ target: this });
            this.set('actionReceiver', proxy);
          });
      })
  });

});