define('ember-idx-forms/submit_button', ['exports', 'ember', 'ember-idx-forms/mixins/in_form'], function (exports, Em, InFormMixin) {

  'use strict';

  exports['default'] = Em['default'].Component.extend(InFormMixin['default'], {
    classes: 'btn btn-default',
    classNames: ['form-group'],
    text: 'Submit',
    type: 'submit',
    attributeBindings: ['disabled'],
    horiClass: 'col-sm-offset-2 col-sm-10',
    disabled: Em['default'].computed('model.isValid', function () {
      if (!Em['default'].isNone(this.get('model.isValid'))) {
        return !this.get('model.isValid');
      } else {
        return false;
      }
    })
  });

});