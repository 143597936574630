define('ember-idx-forms/mixins/in_form', ['exports', 'ember'], function (exports, Em) {

  'use strict';

  exports['default'] = Em['default'].Mixin.create({
    form: Em['default'].computed('parentView', function () {
      var parentView;
      parentView = this.get('parentView');
      while (parentView) {
        if (parentView.get('tagName') === 'form') {
          return parentView;
        }
        parentView = parentView.get('parentView');
      }
      return Em['default'].assert(false, 'Cannot find form');
    }),
    model: Em['default'].computed('form', 'form.model', function () {
      return this.get('form.model');
    })
  });

});