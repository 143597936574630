define('ember-i18n/utils/macro', ['exports', 'ember'], function (exports, Ember) {

  'use strict';


  exports['default'] = createTranslatedComputedProperty;

  var keys = Object.keys;
  var get = Ember['default'].get;

  // @public

  function createTranslatedComputedProperty(key) {
    var interpolations = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

    return Ember['default'].computed(values(interpolations), function () {
      var i18n = get(this, 'i18n');
      Ember['default'].assert("Cannot translate " + key + ". " + this + " does not have an i18n.", i18n);
      return i18n.t(key, mapPropertiesByHash(this, interpolations));
    });
  }

  function values(object) {
    return keys(object).map(function (key) {
      return object[key];
    });
  }

  function mapPropertiesByHash(object, hash) {
    var result = {};

    keys(hash).forEach(function (key) {
      result[key] = get(object, hash[key]);
    });

    return result;
  }

});