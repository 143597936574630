define('ember-idx-forms/input', ['exports', 'ember', 'ember-idx-forms/group', 'ember-idx-forms/mixins/control'], function (exports, Em, FormGroupComponent, ControlMixin) {

  'use strict';

  exports['default'] = FormGroupComponent['default'].extend({
    controlView: Em['default'].TextField.extend(ControlMixin['default'], {
      attributeBindings: ['placeholder', 'required', 'autofocus', 'disabled'],
      placeholder: Em['default'].computed.alias('parentView.placeholder'),
      required: Em['default'].computed.alias('parentView.required'),
      autofocus: Em['default'].computed.alias('parentView.autofocus'),
      disabled: Em['default'].computed.alias('parentView.disabled'),
      type: Em['default'].computed.alias('parentView.type'),
      model: Em['default'].computed.alias('parentView.model'),
      propertyName: Em['default'].computed.alias('parentView.propertyName')
    }),
    property: void 0,
    label: void 0,
    placeholder: void 0,
    required: void 0,
    autofocus: void 0,
    disabled: void 0,
    controlWrapper: Em['default'].computed('form.form_layout', function () {
      if (this.get('form.form_layout') === 'horizontal') {
        return 'col-sm-10';
      }
      return null;
    })
  });

});