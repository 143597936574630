define('ember-tooltips/utils/render-tooltip', ['exports', 'ember'], function (exports, Ember) {

  'use strict';



  exports['default'] = renderTooltip;
  /**
  A utility to attach a tooltip to a DOM element.

  @submodule utils
  @method renderTooltip
  @param {Element} domElement The DOM element, not jQuery element, to attach the tooltip to
  @param {Object} options The tooltip options to render the tooltip with
  */

  var Tooltip = window.Tooltip;
  var $ = Ember['default'].$;
  var run = Ember['default'].run;

  function renderTooltip() {
    var domElement = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];
    var options = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

    var typeClass = options.typeClass;

    var tooltip = undefined;

    Ember['default'].assert('You must pass a DOM element as the first argument to the renderTooltip util', !!domElement.tagName);

    if (typeClass) {
      options.typeClass = 'tooltip-' + typeClass;
    }

    if (!options.event) {
      options.event = 'hover';
    }

    if (options.duration && typeof options.duration === 'string') {
      options.duration = parseInt(options.duration, 10);

      /* Remove invalid parseInt results */

      if (isNaN(options.duration) || !isFinite(options.duration)) {
        options.duration = null;
      }
    }

    tooltip = new Tooltip(options.content, options);

    tooltip.attach(domElement);

    if (options.event !== 'manual') {
      $(domElement)[options.event](function () {
        var willShow = tooltip.hidden;

        tooltip.toggle();

        /* Clean previously queued removal (if present) */

        run.cancel(tooltip._hideTimer);

        if (willShow && options.duration) {

          /* Hide tooltip after specified duration */

          var hideTimer = run.later(function () {
            tooltip.hide();
          }, options.duration);

          /* Save timer ID for cancelling */

          tooltip._hideTimer = hideTimer;
        }
      });
    }

    return tooltip;
  }

});