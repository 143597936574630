define('ember-idx-utils/config', ['exports', 'ember'], function (exports, Em) {

  'use strict';

  exports['default'] = Em['default'].Namespace.extend({
    _configs: Em['default'].Object.create(),
    getConfig: function(name) {
      var config;
      config = this._configs.get(name);
      return config;
    },
    addConfig: function(name, config) {
      var defaultConfig, newConfig;
      defaultConfig = this._configs.get('default');
      newConfig = Em['default'].Object.create(config);
      newConfig = Em['default'].$.extend(true, newConfig, defaultConfig);
      return this._configs.set(name, newConfig);
    }
  });

});