define('ember-idx-forms/inline/inline', ['exports', 'ember'], function (exports, Em) {

    'use strict';

    var set = Em['default'].set;
    var get = Em['default'].get;
    var d = Em['default'].debug;
    var fmt = Em['default'].fmt;

    /**
    * This component is the base component of every inline component,
    * It renders the value as a link, clicking on the link produce a save | cancel buttons
    * 
    * Components should use this component to render the default inline mode while render
    * its edit mode in this component's yield.
    * It does not conduct the edit mode details since the edit mode of every component is too different.
    * 
    * @class Inline
    * @public
    **/
    exports['default'] = Em['default'].Component.extend({
        classNameBindings: ['edit:inline-edit:inline-non-edit'],
        tagName: 'a',
        edit: false,
        //componentClicked: (function(e) {
        //  toggle(this, 'edit')
        // }).on('click'),

        linkClicked: (function () {
            if (!get(this, 'edit')) {
                this.send('toggle');
            }
        }).on('click'),

        actions: {
            save: function save() {
                d("Saving %@".fmt(get(this, 'elementId')));
                this.send('toggle');
            },
            cancel: function cancel() {
                d("Canceling %@".fmt(get(this, 'elementId')));
                this.send('toggle');
            },
            toggle: function toggle() {
                d("Toggling %@".fmt(get(this, 'elementId')));
                this.toggleProperty('edit');
            }
        }
    });

});