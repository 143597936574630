define('ember-validations/errors', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var get = Ember['default'].get;
  var set = Ember['default'].set;

  exports['default'] = Ember['default'].Object.extend({
    unknownProperty: function unknownProperty(property) {
      set(this, property, Ember['default'].A());
      return get(this, property);
    }
  });

});