define('ember-cli-i18n/utils/t', ['exports', 'ember', 'ember-cli-i18n/services/i18n', 'ember-cli-i18n/utils/stream'], function (exports, Ember, defaultservice, stream) {

  'use strict';

  var bind = Ember['default'].run.bind;

  function T(attributes) {
    for(var key in attributes) {
      this[key] = attributes[key];
    }
    this.t = function(path, values) {
      var service = this.container.lookupFactory('service:i18n');
      var result;
      var locale;

      if (!Ember['default'].isArray(values)) {
        values = Array.prototype.slice.call(arguments, 1);
      }

      if (!service || service.lookupLocalePath) {
        service = defaultservice['default'];
      }

      path = stream.read(path);

      locale = service.resolveLocale(this.container, this);
      result = service.getLocalizedPath(locale, path, this.container, this);
      result = service.applyPluralizationRules(result, locale, path, this.container, values, this);

      Ember['default'].assert('Missing translation for key "' + path + '".', result);
      Ember['default'].assert('Translation for key "' + path + '" is not a string.', Ember['default'].typeOf(result) === 'string');

      return service.fmt(result, stream.readArray(values));
    };
  }

  T.create = function(attributes) {
    var t = new T(attributes);
    var fn = bind(t, t.t);
    fn.destroy = function() {};
    return fn;
  };

  exports['default'] = T;

});