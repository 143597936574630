define('ember-idx-forms/control_help', ['exports', 'ember', 'ember-idx-forms/mixins/in_form'], function (exports, Em, InFormMixin) {

  'use strict';

  exports['default'] = Em['default'].Component.extend(InFormMixin['default'], {
    tagName: 'span',
    classNames: ['help-block'],
    classNameBindings: ['extraClass', 'horiClassCalc'],
    text: void 0,
    extraClass: void 0,
    horiClass: 'col-sm-offset-2 col-sm-10',
    horiClassCalc: Em['default'].computed('form.isHorizontal', function () {
      if (this.get('form.isHorizontal') && this.get('horiClass')) {
        return this.get('horiClass');
      }
    }),
    init: function init() {
      this._super();
      return Em['default'].Binding.from('model.errors.' + this.get('parentView.propertyName')).to('errors').connect(this);
    },
    helpText: Em['default'].computed('text', 'errors.firstObject', function () {
      return this.get('errors.firstObject.message') || this.get('errors.firstObject') || this.get('text');
    }),
    hasHelp: Em['default'].computed('helpText', function () {
      var _ref;
      return ((_ref = this.get('helpText')) != null ? _ref.length : void 0) > 0;
    }),
    hasError: Em['default'].computed('errors.length', function () {
      var _ref;
      return (_ref = this.get('errors')) != null ? _ref.length : void 0;
    })
  });

});