define('ember-cli-i18n/rules/fr', ['exports', 'ember-cli-i18n/rules/base'], function (exports, base) {

  'use strict';

  exports['default'] = function(value, result, path, countryCode) {
    return base['default'](['one', 'other'], value, result, path, countryCode, function(value) {
      switch(true) {
        case (value >= 0 && value < 2): return 'one';
        default: return 'other';
      }
    });
  }

});