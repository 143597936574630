define('ember-idx-forms/text', ['exports', 'ember', 'ember-idx-forms/group', 'ember-idx-forms/mixins/control'], function (exports, Em, FormGroupComponent, ControlMixin) {

  'use strict';

  exports['default'] = FormGroupComponent['default'].extend({
    controlView: Em['default'].TextArea.extend(ControlMixin['default'], {
      attributeBindings: ['placeholder'],
      placeholder: Em['default'].computed.alias('parentView.placeholder'),
      model: Em['default'].computed.alias('parentView.model'),
      propertyName: Em['default'].computed.alias('parentView.propertyName'),
      rows: Em['default'].computed.alias('parentView.rows')
    }),
    property: void 0,
    label: void 0,
    placeholder: void 0,
    rows: 4,
    controlWrapper: Em['default'].computed('form.form_layout', function () {
      if (this.get('form.form_layout') === 'horizontal') {
        return 'col-sm-10';
      }
      return null;
    })
  });

});