define('ember-tooltips/components/tooltip-on-parent', ['exports', 'ember', 'ember-tooltips/templates/components/tooltip-on-parent'], function (exports, Ember, layout) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    attributeBindings: ['style'],
    layout: layout['default'],

    style: Ember['default'].computed(function () {
      return Ember['default'].String.htmlSafe('display:none;');
    }),

    registerOnParent: Ember['default'].on('didInsertElement', function () {
      var parentView = this.get('parentView');

      if (parentView.renderTooltip) {
        parentView.renderTooltip(this);
      } else {
        Ember['default'].warn('No renderTooltip method found on the parent view of the {{tooltip-on-parent}} component');
      }

      this.remove();
    })

  });

});