auxiliary = {
    senseoflive: function () {
        console.log("sense of live is 42!");
    },

    printProperties: function (obj){
        props = Object.getOwnPropertyNames(obj);
        props.forEach(function (el, i) { console.log(el.toString()+";"+i.toString()); });
    },

    erzeugeFlachesObjekt: function (obj, erg) {
        props = Object.getOwnPropertyNames(obj);
        props.forEach(function (el, i) {
            erg.flachesObjekt[el.toString()] = obj[el.toString()];
        });
    },
    
    save: function (tmp) {
        console.log("--> save()");
        erg = { flachesObjekt: {}};
        tmp.forEach(function (el, i) { auxiliary.erzeugeFlachesObjekt(el, erg); });

        return erg.flachesObjekt;
    }
};//auxiliary