define('ember-validations/index', ['exports', 'ember-validations/mixin'], function (exports, Mixin) {

  'use strict';

  exports.validator = validator;

  exports['default'] = Mixin['default'];

  function validator(callback) {
    return { callback: callback };
  }

});