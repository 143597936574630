define('ember-cli-i18n/rules/base', ['exports', 'ember', 'ember-cli-i18n/utils/stream'], function (exports, Ember, stream) {

  'use strict';

  exports['default'] = function(keys, value, result, path, countryCode, fn) {
    var type;

    // Handle results that come in as a Stream
    value = stream.read(value);

    if (hasValidKey(keys, result)) {
      if(Ember['default'].typeOf(value) === 'number') {
        type = fn(value);
      } else {
        Ember['default'].assert('Translation for key "' + path + '" expected a count value.', false);
      }
    } else {
      Ember['default'].assert('Translation for key "' + path + '" does not contain valid pluralization types for language "' + countryCode + '". Must contain one of the follow keys: ' + keys.join(', '), false);
    }

    return { result: result[type], path: path + '.' + type };
  }

  function hasValidKey(keys, result) {
    var resultKeys = Ember['default'].keys(result);

    for(var i = 0; i < resultKeys.length; i++) {
      if (keys.indexOf(resultKeys[i]) > -1) {
       return true;
      }
    }

    return false;
  }

});