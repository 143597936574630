define('ember-idx-forms/select', ['exports', 'ember', 'ember-idx-forms/group', 'ember-idx-forms/mixins/control'], function (exports, Em, FormGroupComponent, ControlMixin) {

  'use strict';

  exports['default'] = FormGroupComponent['default'].extend({
    v_icons: false,
    controlView: Em['default'].Select.extend(ControlMixin['default'], {
      model: Em['default'].computed.alias('parentView.model'),
      propertyName: Em['default'].computed.alias('parentView.propertyName'),
      content: Em['default'].computed.alias('parentView.content'),
      optionValuePath: Em['default'].computed.alias('parentView.optionValuePath'),
      optionLabelPath: Em['default'].computed.alias('parentView.optionLabelPath'),
      prompt: Em['default'].computed.alias('parentView.prompt'),
      multiple: Em['default'].computed.alias('parentView.multiple')
    }),
    propertyIsModel: false,
    property: void 0,
    content: void 0,
    optionValuePath: void 0,
    optionLabelPath: void 0,
    prompt: void 0,
    controlWrapper: Em['default'].computed('form.form_layout', function () {
      if (this.get('form.form_layout') === 'horizontal') {
        return 'col-sm-10';
      }
      return null;
    })
  });

});