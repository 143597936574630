define('ember-idx-utils/utils/delay', ['exports', 'ember'], function (exports, Em) {

  'use strict';

  var delay = function(ms) {
    ms = ms || 1500;
    return new Em['default'].RSVP.Promise(function(resolve) {
      Em['default'].run.later(this, resolve, ms);
    });
  };

  exports['default'] = delay;

});