define('ember-cli-tooltipster/components/tool-tipster', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({

        classNameBindings: ['tooltip'],

        attributeBindings: ['title'],

        updateTitle: Ember['default'].observer('title', function() {
          Ember['default'].run.schedule('afterRender', this, function() {
            this.$().tooltipster('content', this.get('title'));
          });
        }),

        updateContent: Ember['default'].observer('content', function(){
          Ember['default'].run.schedule('afterRender', this, function() {
            this.$().tooltipster('content', this.get('content'));
          });
        }),

        /**
         * Set how tooltips should be activated and closed.
         * Default: 'hover'
         * Options: [hover, click]
         * @type {String}
         */
        triggerEvent: 'hover',

        tooltipsterOptions: ['animation', 'arrow', 'arrowColor', 'content', 'contentAsHTML', 'debug', 'delay', 'interactive',
            'minWidth', 'maxWidth', 'offsetX', 'offsetY', 'position', 'positionTracker', 'speed', 'timer', 'theme',
            'updateAnimation'
        ],

        _initializeTooltipster: Ember['default'].on('didInsertElement', function() {
            var _this = this;
            var options = {};

            _this.get('tooltipsterOptions').forEach(function(item) {
                if (!Ember['default'].isEmpty(_this.get(item))) {
                    options[item] = _this.get(item);
                }
            });

            options.trigger = _this.get('triggerEvent');
            options.functionInit = Ember['default'].$.proxy(this.functionInit, this);
            options.functionBefore = Ember['default'].$.proxy(this.functionBefore, this);
            options.functionReady = Ember['default'].$.proxy(this.functionReady, this);
            options.functionAfter = Ember['default'].$.proxy(this.functionAfter, this);
            options.positionTrackerCallback = Ember['default'].$.proxy(this.positionTrackerCallback, this);

            this.$().tooltipster(options);

        }),

        _destroyTooltipster: Ember['default'].on('willDestroyElement', function() {
            this.$().tooltipster('destroy');
        }),
        /**
        * Send action ´open´ when open info
        *
        * @method functionBefore
        **/
        functionReady: function(origin, tooltip) {
            this.sendAction('open', tooltip);
        },

        /**
        * Send action close when close info
        *
        * @method functionBefore
        **/
        functionAfter: function(/*origin*/) {
            this.sendAction('close');
        }
    });

});