define('ember-idx-tree/tree-branch', ['exports', 'ember', 'ember-idx-utils/mixin/with-config'], function (exports, Em, WithConfigMixin) {

  'use strict';

  exports['default'] = Em['default'].Component.extend(WithConfigMixin['default'], {

    /**
     * The model to render its children within this branch
     * this property is set during component markup creation
     */
    model: undefined,

    /**
     * A list of {{#crossLink "TreeNode"}}nodes{{/crossLink}} instances.
     */
    children: Em['default'].computed.alias('model.children'),

    /**
     * True if node's children should be loaded asynchronously
     * This gives the opportunity to the user to invoke an async call to the server to retrieve data for the current
     * branch being opened
     */
    async: false,
    tagName: 'ul',
    layoutName: 'em-tree-branch',
    classNameBindings: ['styleClasses'],
    styleClasses: (function() {
      var _ref;
      return (_ref = this.get('config.tree.branchClasses')) != null ? _ref.join(" ") : void 0;
    }).property()
  });

});