define('ember-cli-tree/components/ember-tree/trigger-expand', ['exports', 'ember', 'ember-cli-tree/templates/components/ember-tree/trigger-expand'], function (exports, Ember, layout) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    layout: layout['default'],
    classNames: ['ember-tree-node-trigger-expand'],
    tagName: 'span'
  });

});