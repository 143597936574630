define('ember-idx-forms/form', ['exports', 'ember', 'ember-idx-forms/utils/utils'], function (exports, Em, Utils) {

  'use strict';

  exports['default'] = Em['default'].Component.extend({
    tagName: 'form',
    classNameBindings: ['form_layout_class'],
    attributeBindings: ['role'],
    role: 'form',
    form_layout_class: Em['default'].computed('form_layout', function () {
      switch (this.get('form_layout')) {
        case 'horizontal':
        case 'inline':
          return "form-" + this.get('form_layout');
        default:
          return 'form';
      }
    }),
    //isDefaultLayout: Utils.createBoundSwitchAccessor('form', 'form_layout', 'form'),
    //isInline: Utils.createBoundSwitchAccessor('inline', 'form_layout', 'form'),
    //isHorizontal: Utils.createBoundSwitchAccessor('horizontal', 'form_layout', 'form'),
    action: 'submit',
    model: void 0,
    form_layout: 'form',
    submit_button: true,
    v_icons: true,

    /*
    Form submit
    
    Optionally execute model validations and perform a form submission.
     */
    submit: function submit(e) {
      var promise;
      if (e) {
        e.preventDefault();
      }
      if (Em['default'].isNone(this.get('model.validate'))) {
        return this.get('targetObject').send(this.get('action'));
      } else {
        promise = this.get('model').validate();
        return promise.then((function (_this) {
          return function () {
            if (_this.get('model.isValid')) {
              return _this.get('targetObject').send(_this.get('action'));
            }
          };
        })(this));
      }
    }
  });

});