define('ember-idx-forms/label', ['exports', 'ember', 'ember-idx-forms/mixins/in_form'], function (exports, Em, InFormMixin) {

  'use strict';

  exports['default'] = Em['default'].Component.extend(InFormMixin['default'], {
    tagName: 'label',
    classNames: ['control-label'],
    classNameBindings: ['extraClass', 'inlineClassCalc', 'horiClassCalc'],
    attributeBindings: ['for'],
    horiClass: 'col-sm-2',
    horiClassCalc: Em['default'].computed('form.isHorizontal', function () {
      if (this.get('form.isHorizontal') && this.get('horiClass')) {
        return this.get('horiClass');
      }
    }),
    inlineClass: 'sr-only',
    inlineClassCalc: Em['default'].computed('form.form_layout', function () {
      if (this.get('form.isInline') && this.get('inlineClass')) {
        return this.get('inlineClass');
      }
    })
  });

});