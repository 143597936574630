define('ember-i18n/config/he', ['exports', 'ember-i18n/config/en'], function (exports, en) {

  'use strict';

  exports['default'] = {
    rtl: true,

    pluralForm: en['default'].pluralForm
  };

});