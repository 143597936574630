define('ember-idx-utils/mixin/with-config', ['exports', 'ember'], function (exports, Em) {

  'use strict';

  exports['default'] = Em['default'].Mixin.create({
    configName: Em['default'].computed(function() {
      var config;
      config = this.nearestWithProperty('configName');
      if (config) {
        return config.get('configName');
      } else {
        return 'default';
      }
    }),
    config: Em['default'].computed('configName', function() {
      return Em['default'].IdxConfig.getConfig(this.get('configName'));
    })
  });

});