define('ember-validations/validators/local/presence', ['exports', 'ember', 'ember-validations/validators/base', 'ember-validations/messages'], function (exports, Ember, Base, Messages) {

  'use strict';

  var get = Ember['default'].get;

  exports['default'] = Base['default'].extend({
    init: function init() {
      this._super();
      /*jshint expr:true*/
      if (this.options === true) {
        this.options = {};
      }

      if (this.options.message === undefined) {
        this.options.message = Messages['default'].render('blank', this.options);
      }
    },
    call: function call() {
      if (Ember['default'].isBlank(get(this.model, this.property))) {
        this.errors.pushObject(this.options.message);
      }
    }
  });

});