define('ember-idx-forms/utils/utils', ['exports'], function (exports) {

  'use strict';

  var Utils;
  exports['default'] = Utils = {
    createBoundSwitchAccessor: function createBoundSwitchAccessor(switchValue, myProperty, myDefault) {
      if (myDefault == null) {
        myDefault = 'default';
      }
      return Em.computed(myProperty, function (key, value) {
        if (arguments.length === 2) {
          this.set(myProperty, value ? switchValue : myDefault);
        }
        return this.get(myProperty) === switchValue;
      });
    },
    namelize: function namelize(string) {
      return string.underscore().split('_').join(' ').capitalize();
    }
  };

});