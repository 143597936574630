define('ember-idx-forms/mixins/has_property_validation', ['exports', 'ember'], function (exports, Em) {

  'use strict';

  exports['default'] = Em['default'].Mixin.create({
    init: function init() {
      this._super();
      Em['default'].assert(!Em['default'].isNone(this.get('propertyName')), 'propertyName is required.');
      return Em['default'].Binding.from('model.errors.' + this.get('propertyName')).to('errors').connect(this);
    },
    status: Em['default'].computed('errors.length', function () {
      if (this.get('errors.length')) {
        return 'error';
      } else {
        return 'success';
      }
    })
  });

});