define('ember-cli-jstree/mixins/ember-jstree-actions', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Mixin.create({
        actions: {
            redraw: function redraw() {
                // Redraw true currently does not work as intended. Need to investigate.
                this._refreshTree();
            },

            destroy: function destroy() {
                var o = this.get('treeObject');
                if (null !== o) {
                    if (!Ember['default'].testing && !this.get('_isDestroying')) {
                        o.jstree(true).destroy();
                    }

                    this.sendAction('eventDidDestroy');
                }
            },

            getNode: function getNode(nodeId) {
                if (typeof nodeId !== "string") {
                    throw new Error('getNode() requires a node ID to be passed to it to return the node!');
                }

                var o = this.get('treeObject');
                if (null !== o) {
                    this.sendAction('actionGetNode', o.jstree(true).get_node(nodeId));
                }
            },

            getText: function getText(obj) {
                var o = this.get('treeObject');
                if (null !== o) {
                    this.sendAction('actionGetText', o.jstree(true).get_text(obj));
                }
            },

            getPath: function getPath(obj, glue, ids) {
                var o = this.get('treeObject');
                if (null !== o) {
                    this.sendAction('actionGetPath', o.jstree(true).get_path(obj, glue, ids));
                }
            },

            getChildrenDom: function getChildrenDom(obj) {
                var o = this.get('treeObject');
                if (null !== o) {
                    this.sendAction('actionGetChildrenDom', o.jstree(true).get_children_dom(obj));
                }
            },

            getContainer: function getContainer() {
                var o = this.get('treeObject');
                if (null !== o) {
                    this.sendAction('actionGetContainer', o.jstree(true).get_container());
                }
            },

            getParent: function getParent(obj) {
                obj = obj || "#";
                var o = this.get('treeObject');
                if (null !== o) {
                    this.sendAction('actionGetParent', o.jstree(true).get_parent(obj));
                }
            },

            loadNode: function loadNode(obj, cb) {
                var o = this.get('treeObject');
                if (null !== o) {
                    this.sendAction('actionLoadNode', o.jstree(true).load_node(obj, cb));
                }
            },

            loadAll: function loadAll(obj, cb) {
                var o = this.get('treeObject');
                if (null !== o) {
                    this.sendAction('actionLoadAll', o.jstree(true).load_all(obj, cb));
                }
            },

            openNode: function openNode(obj, cb, animation) {
                var o = this.get('treeObject');
                if (null !== o) {
                    o.jstree(true).open_node(obj, cb, animation);
                }
            },

            openAll: function openAll(obj, animation) {
                var o = this.get('treeObject');
                if (null !== o) {
                    o.jstree(true).open_all(obj, animation);
                }
            },

            closeNode: function closeNode(obj, cb) {
                var o = this.get('treeObject');
                if (null !== o) {
                    o.jstree(true).close_node(obj, cb);
                }
            },

            closeAll: function closeAll(obj, animation) {
                var o = this.get('treeObject');
                if (null !== o) {
                    o.jstree(true).close_all(obj, animation);
                }
            },

            toggleNode: function toggleNode(obj) {
                var o = this.get('treeObject');
                if (null !== o) {
                    o.jstree(true).toggle_node(obj);
                }
            },

            createNode: function createNode(obj, node, pos, callback, is_loaded) {
                var o = this.get('treeObject');
                if (null !== o) {
                    this.sendAction('actionCreateNode', o.jstree(true).create_node(obj, node, pos, callback, is_loaded));
                }
            },

            renameNode: function renameNode(obj, val) {
                var o = this.get('treeObject');
                if (null !== o) {
                    this.sendAction('actionRenameNode', o.jstree(true).rename_node(obj, val));
                }
            },

            moveNode: function moveNode(obj, par, pos, callback, is_loaded) {
                var o = this.get('treeObject');
                if (null !== o) {
                    o.jstree(true).move_node(obj, par, pos, callback, is_loaded);
                }
            },

            copyNode: function copyNode(obj, par, pos, callback, is_loaded) {
                var o = this.get('treeObject');
                if (null !== o) {
                    o.jstree(true).copy_node(obj, par, pos, callback, is_loaded);
                }
            },

            deleteNode: function deleteNode(obj) {
                var o = this.get('treeObject');
                if (null !== o) {
                    this.sendAction('actionDeleteNode', o.jstree(true).delete_node(obj));
                }
            },

            selectNode: function selectNode(obj, suppress_event) {
                var o = this.get('treeObject');
                if (null !== o) {
                    o.jstree(true).select_node(obj, suppress_event);
                }
            },

            deselectNode: function deselectNode(obj, suppress_event) {
                var o = this.get('treeObject');
                if (null !== o) {
                    o.jstree(true).deselect_node(obj, suppress_event);
                }
            },

            selectAll: function selectAll(suppress_event) {
                var o = this.get('treeObject');
                if (null !== o) {
                    o.jstree(true).select_all(suppress_event);
                }
            },

            deselectAll: function deselectAll(suppress_event) {
                var o = this.get('treeObject');
                if (null !== o) {
                    o.jstree(true).deselect_all(suppress_event);
                }
            },

            lastError: function lastError() {
                var o = this.get('treeObject');
                if (null !== o) {
                    var e = o.jstree(true).last_error();
                    this.set('_lastError', e);
                    this.sendAction('actionLastError', e);
                }
            },

            deselectNodes: function deselectNodes() {
                var o = this.get('treeObject');
                if (null !== o) {
                    o.jstree(true).deselect_all();
                }
            },

            selectNodes: function selectNodes(property, values) {
                var treeObject = this.get('treeObject');
                if (null !== treeObject) {
                    if ('id' === property) {
                        // If property is ID, can use get_node, which is faster than search.
                        if (Ember['default'].$.isArray(values)) {
                            var nodes = [];
                            for (var i = 0; i < values.length; i++) {
                                var node = treeObject.jstree(true).get_node(values[i]);
                                nodes.push(node);
                            }
                            treeObject.jstree(true).select_node(nodes, true, true);
                        }
                    } else {
                        if (this.plugins.indexOf("search") === -1) {
                            Ember['default'].assert("'search' plugin is required to perform 'selectNodes' on properties other than 'id'");
                            return;
                        }

                        this.set('search_property', property);

                        treeObject.on('search.jstree', (function (event, data) {
                            treeObject.jstree(true).select_node(data.nodes, true, true);
                        }).bind(this));

                        if (Ember['default'].$.isArray(values)) {
                            for (var i = 0; i < values.length; i++) {
                                treeObject.jstree(true).search(values[i]);
                            }

                            treeObject.jstree(true).clear_search();
                        }
                    }
                }
            }
        }
    });

});