define('ember-validations/validators/local/absence', ['exports', 'ember', 'ember-validations/validators/base', 'ember-validations/messages'], function (exports, Ember, Base, Messages) {

  'use strict';

  var get = Ember['default'].get;
  var set = Ember['default'].set;

  exports['default'] = Base['default'].extend({
    init: function init() {
      this._super();
      /*jshint expr:true*/
      if (this.options === true) {
        set(this, 'options', {});
      }

      if (this.options.message === undefined) {
        set(this, 'options.message', Messages['default'].render('present', this.options));
      }
    },
    call: function call() {
      if (!Ember['default'].isEmpty(get(this.model, this.property))) {
        this.errors.pushObject(this.options.message);
      }
    }
  });

});